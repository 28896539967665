<template>
	<div class="pui-form">
		<pui-form-header v-if="modelLoaded" :showHeader="!isCreatingElement">
			<mships-form-header :modelPk="modelPk"></mships-form-header>
		</pui-form-header>
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout wrap>
				<v-flex xs12 md4>
					<pui-field-set :title="getTitleGeneralData">
						<v-layout wrap>
							<v-flex xs12 md6 lg6 x6 align-self-center>
								<!-- INACTIVEIND -->
								<pui-checkbox
									:id="`inactiveind-mships`"
									v-model="value.mship.inactiveind"
									:label="$t('mships.inactiveind')"
									:disabled="isCreatingElement"
									required
									style="margin-bottom: 20px; margin-left: -2%"
									true-value="1"
									false-value="0"
								></pui-checkbox>
							</v-flex>
							<v-flex xs12 md6 lg6 x6>
								<!-- SHIPSTATUSID -->
								<pui-select
									:id="`shipstatusid-mships`"
									:attach="`shipstatusid-mships`"
									:label="$t('mships.shipstatusid')"
									:disabled="isCreatingElement"
									required
									toplabel
									clearable
									modelName="mshipstatus"
									v-model="model"
									reactive
									:itemsToSelect="shipstatusidItemsToSelect"
									:modelFormMapping="{ shipstatusid: 'shipstatusid' }"
									:itemValue="['shipstatusid']"
									:itemText="(item) => `${item.desc}`"
								></pui-select>
							</v-flex>
							<v-flex xs12 md6 lg6 x6>
								<!-- shipid -->
								<pui-number-field
									:id="`shipid-mships`"
									v-model="value.mship.shipid"
									:label="$t('mships.shipid')"
									:disabled="isCreatingElement"
									required
									toplabel
									integer
									min="0"
									max="99999"
								></pui-number-field>
							</v-flex>
							<v-flex xs12 md6 lg6 x6>
								<!-- VERSION -->
								<pui-number-field
									:id="`version-mships`"
									v-model="value.mship.version"
									:label="$t('mships.version')"
									:disabled="isCreatingElement"
									required
									toplabel
									integer
									min="0"
									max="99999"
								></pui-number-field>
							</v-flex>
							<v-flex xs12 md6 lg6 x6>
								<!-- INIVALIDITY -->
								<pui-date-field
									:id="`inivalidity-mships`"
									v-model="value.mship.inivalidity"
									:label="$t('mships.inivalidity')"
									:disabled="isCreatingElement"
									required
									toplabel
								></pui-date-field>
							</v-flex>
							<v-flex xs12 md6 lg6 x6>
								<!-- ENDVALIDITY -->
								<pui-date-field
									:id="`endvalidity-mships`"
									v-model="value.mship.endvalidity"
									:label="$t('mships.endvalidity')"
									:min="value.mship.inivalidity"
									disabled
									toplabel
								></pui-date-field>
							</v-flex>
						</v-layout>

						<v-layout wrap>
							<v-flex xs12 md12 lg12 x12>
								<!-- SHIPNAME -->
								<pui-text-field
									:id="`shipname-mships`"
									v-model="value.mship.shipname"
									:label="$t('mships.shipname')"
									:disabled="isCreatingElement"
									required
									toplabel
									maxlength="35"
								></pui-text-field>
							</v-flex>
							<v-flex xs12 md12 lg12 x12>
								<!-- SHIPTYPECODE -->
								<pui-select
									:id="`shiptypecode-mships`"
									:attach="`shiptypecode-mships`"
									:label="$t('mships.shiptypecode')"
									:disabled="isCreatingElement"
									required
									toplabel
									clearable
									modelName="mshiptypes"
									v-model="model"
									reactive
									:itemsToSelect="shiptypecodeItemsToSelect"
									:modelFormMapping="{ shiptypecode: 'shiptypecode' }"
									:itemValue="['shiptypecode']"
									:itemText="(item) => `${item.shiptypecode} - ${item.shiptypedesc}`"
								></pui-select>
							</v-flex>
							<v-flex xs12 md12 lg6 x6>
								<!-- IMO -->
								<pui-text-field
									:id="`imo-mships`"
									v-model="value.mship.imo"
									:label="$t('mships.imo')"
									:disabled="isCreatingElement"
									required
									toplabel
									type="number"
									:rules="[($v) => (!!$v && $v.length >= 7) || 'The minimum length of the attribute IMO is 7 character(e)']"
									maxlength="7"
								></pui-text-field>
							</v-flex>
							<v-flex xs12 md12 lg6 x6>
								<!-- CALLSIGN -->
								<pui-text-field
									:id="`callsign-mships`"
									v-model="value.mship.callsign"
									:label="$t('mships.callsign')"
									:disabled="isCreatingElement"
									toplabel
									maxlength="7"
								></pui-text-field>
							</v-flex>
							<v-flex xs12 md12 lg12 x12>
								<!-- FLAG -->
								<pui-select
									:id="`flag-mships`"
									:attach="`flag-mships`"
									:label="$t('mships.flag')"
									:disabled="isCreatingElement"
									required
									toplabel
									clearable
									modelName="mcountries"
									v-model="model"
									reactive
									:itemsToSelect="flagItemsToSelect"
									:modelFormMapping="{ countrycode: 'flag' }"
									:itemValue="['countrycode']"
									:itemText="(item) => `${item.countrycode} - ${item.countrydesc}`"
								></pui-select>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<v-flex xs12 md12 lg6 x6>
								<!-- MMSI -->
								<pui-text-field
									:id="`mmsi-mships`"
									v-model="value.mship.mmsi"
									:label="$t('mships.mmsi')"
									:disabled="isCreatingElement"
									toplabel
									maxlength="9"
								></pui-text-field>
							</v-flex>
							<v-flex xs12 md12 lg6 x2>
								<!-- INMARSATCALLNR -->
								<pui-text-field
									:id="`inmarsatcallnr-mships`"
									v-model="value.mship.inmarsatcallnr"
									:label="$t('mships.inmarsatcallnr')"
									:disabled="isCreatingElement"
									toplabel
									maxlength="50"
								></pui-text-field>
							</v-flex>
						</v-layout>
					</pui-field-set>
				</v-flex>
				<v-flex xs12 md8>
					<pui-field-set :title="getTitleTechnicalData">
						<v-layout wrap>
							<v-flex xs12 md3 lg3 x3>
								<!-- LENGTH -->
								<pui-number-field
									:id="`length-mships`"
									v-model="value.mship.length"
									:label="$t('mships.length')"
									:disabled="isCreatingElement"
									required
									toplabel
									maxlength="5"
									decimals="2"
									suffix="m"
									min="0"
								></pui-number-field>
							</v-flex>
							<v-flex xs12 md3 lg3 xl3>
								<!-- BEAM -->
								<pui-number-field
									:id="`beam-mships`"
									v-model="value.mship.beam"
									:label="$t('mships.beam')"
									:disabled="isCreatingElement"
									required
									toplabel
									suffix="m"
									maxlength="4"
									decimals="2"
									min="0"
								></pui-number-field>
							</v-flex>
							<v-flex xs12 md3 lg3 xl3>
								<!-- MAXDRAFT -->
								<pui-number-field
									:id="`maxdraft-mships`"
									v-model="value.mship.maxdraft"
									:label="$t('mships.maxdraft')"
									:disabled="isCreatingElement"
									required
									suffix="m"
									toplabel
									maxlength="5"
									decimals="2"
									min="0"
								></pui-number-field>
							</v-flex>
							<v-flex xs12 md3 lg3 xl3>
								<!-- DM-->
								<pui-number-field
									:id="`dm-mships`"
									v-model="value.mship.dm"
									:label="$t('mships.dm')"
									:disabled="isCreatingElement"
									suffix="m"
									required
									toplabel
									integer
									min="0"
									max="99999"
								></pui-number-field>
							</v-flex>
							<v-flex xs12 md3 lg3 x3>
								<!-- GROSSTONNAGE -->
								<pui-number-field
									:id="`grosstonnage-mships`"
									v-model="value.mship.grosstonnage"
									:label="$t('mships.grosstonnage')"
									:disabled="isCreatingElement"
									required
									toplabel
									suffix="t"
									maxlength="14"
									decimals="3"
									min="0"
								></pui-number-field>
							</v-flex>
							<v-flex xs12 md3 lg3 x3>
								<!-- REDGROSSTONNAGE -->
								<pui-number-field
									:id="`redgrosstonnage-mships`"
									v-model="value.mship.redgrosstonnage"
									:label="$t('mships.redgrosstonnage')"
									:disabled="isCreatingElement"
									toplabel
									suffix="t"
									integer
									min="0"
								></pui-number-field>
							</v-flex>
							<v-flex xs12 md3 lg3 x3>
								<!-- NETTONNAGE -->
								<pui-number-field
									:id="`nettonnage-mships`"
									v-model="value.mship.nettonnage"
									:label="$t('mships.nettonnage')"
									:disabled="isCreatingElement"
									toplabel
									suffix="t"
									maxlength="14"
									decimals="3"
									min="0"
								></pui-number-field>
							</v-flex>
							<v-flex xs12 md3 lg3 xl3>
								<!-- DWT-->
								<pui-number-field
									:id="`dwt-mships`"
									v-model="value.mship.dwt"
									:label="$t('mships.dwt')"
									:disabled="isCreatingElement"
									suffix="t"
									required
									toplabel
									integer
									min="0"
									max="99999"
								></pui-number-field>
							</v-flex>
							<v-flex xs12 md3 lg3 x3>
								<!-- HULLTYPEID -->
								<pui-select
									:id="`hulltypeid-mships`"
									:attach="`hulltypeid-mships`"
									:label="$t('mships.hulltypeid')"
									:disabled="isCreatingElement"
									toplabel
									clearable
									modelName="mhulltypes"
									v-model="model"
									reactive
									:itemsToSelect="hulltypeidItemsToSelect"
									:modelFormMapping="{ hulltypeid: 'hulltypeid' }"
									:itemValue="['hulltypeid']"
									:itemText="(item) => `${item.desc}`"
								></pui-select>
							</v-flex>
							<v-flex xs12 md3 lg3 x3>
								<!-- MOORINGLINESNUM -->
								<pui-number-field
									:id="`mooringlinesnum-mships`"
									v-model="value.mship.mooringlinesnum"
									:label="$t('mships.mooringlinesnum')"
									:disabled="isCreatingElement"
									toplabel
									integer
									min="0"
									max="99999"
								></pui-number-field>
							</v-flex>
							<v-flex xs12 md3 lg3 x3>
								<!-- MOORINGLINETYPEID -->
								<pui-select
									:id="`mooringlinetypeid-mships`"
									:attach="`mooringlinetypeid-mships`"
									:label="$t('mships.mooringlinetypeid')"
									:disabled="isCreatingElement"
									toplabel
									clearable
									modelName="mmooringlinetypes"
									v-model="model"
									reactive
									:itemsToSelect="mooringlinetypeidItemsToSelect"
									:modelFormMapping="{ mooringlinetypeid: 'mooringlinetypeid' }"
									:itemValue="['mooringlinetypeid']"
									:itemText="(item) => `${item.desc}`"
								></pui-select>
							</v-flex>
							<v-flex xs12 md3 lg3 x3>
								<!-- MOORINGWINCHESNUM -->
								<pui-number-field
									:id="`mooringwinchesnum-mships`"
									v-model="value.mship.mooringwinchesnum"
									:label="$t('mships.mooringwinchesnum')"
									:disabled="isCreatingElement"
									toplabel
									integer
									min="0"
									max="99999"
								></pui-number-field>
							</v-flex>
							<v-flex xs12 md3 lg3 xl3>
								<!-- POWER -->
								<pui-number-field
									:id="`power-mships`"
									v-model="value.mship.power"
									:label="$t('mships.power')"
									:disabled="isCreatingElement"
									suffix="kW"
									toplabel
									integer
									min="0"
								></pui-number-field>
							</v-flex>
							<v-flex xs12 md3 lg3 x3>
								<!-- USEDFUEL -->
								<pui-text-field
									:id="`usedfuel-mships`"
									v-model="value.mship.usedfuel"
									:label="$t('mships.usedfuel')"
									:disabled="isCreatingElement"
									toplabel
									maxlength="100"
								></pui-text-field>
							</v-flex>
							<v-flex xs12 md3 lg3 x3>
								<!-- NUMTHRUSTER -->
								<pui-number-field
									:id="`numthruster-mships`"
									v-model="value.mship.numthruster"
									:label="$t('mships.numthruster')"
									:disabled="isCreatingElement"
									toplabel
									integer
									min="0"
									max="99999"
								></pui-number-field>
							</v-flex>
							<v-flex xs12 md3 lg3 x3>
								<!-- THRUSTERPOSITION -->
								<pui-select
									:id="`thrusterposition-mships`"
									:attach="`thrusterposition-mships`"
									:label="$t('mships.thrusterposition')"
									itemText="text"
									itemValue="value"
									:disabled="isCreatingElement"
									toplabel
									clearable
									v-model="model"
									reactive
									:items="positions"
									:itemsToSelect="thrusterpositionItemsToSelect"
									:modelFormMapping="{ value: 'thrusterposition' }"
								></pui-select>
							</v-flex>
						</v-layout>

						<v-layout wrap>
							<v-flex xs12 md12 lg12 x12>
								<!-- SBTIND -->
								<pui-checkbox
									:id="`sbtind-mships`"
									v-model="value.mship.sbtind"
									:label="$t('mships.sbtind')"
									:disabled="isCreatingElement"
									true-value="1"
									false-value="2"
									style="margin-bottom: 20px"
								></pui-checkbox>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<v-flex xs12 md4 lg4 x4 v-if="model.sbtind == 1">
								<!-- SBTNUMB -->
								<pui-number-field
									:id="`sbtnumb-mships`"
									v-model="value.mship.sbtnumb"
									:label="$t('mships.sbtnumb')"
									:disabled="isCreatingElement"
									toplabel
									integer
									min="0"
									max="99999"
								></pui-number-field>
							</v-flex>
							<v-flex xs12 md4 lg4 x4 v-if="model.sbtind == 1">
								<!-- SBTCAPACITY -->
								<pui-number-field
									:id="`sbtcapacity-mships`"
									v-model="value.mship.sbtcapacity"
									:label="$t('mships.sbtcapacity')"
									:disabled="isCreatingElement"
									toplabel
									decimals="6"
									min="0"
									maxlength="16"
								></pui-number-field>
							</v-flex>
							<v-flex xs12 md4 lg4 x4 v-if="model.sbtind == 1">
								<!-- MEASUREMENTUNITCODE -->
								<pui-select
									:id="`measurementunitcode-mships`"
									:attach="`measurementunitcode-mships`"
									:label="$t('mships.measurementunitcode')"
									:disabled="isCreatingElement"
									toplabel
									clearable
									modelName="mmeasurementunits"
									v-model="model"
									reactive
									:itemsToSelect="measurementUnitCodeItemsToSelect"
									:modelFormMapping="{ measurementunitcode: 'measurementunitcode' }"
									:itemValue="['measurementunitcode']"
									:itemText="(item) => `${item.measurementunitsymbol} - ${item.typedescription}`"
									:fixedFilter="filterMeasurementUnitCode"
								></pui-select>
							</v-flex>
						</v-layout>

						<v-layout wrap>
							<v-flex xs12 md12 lg12 x12 v-if="this.value.mship.shipstatusid == 2">
								<!-- REJECTIONREASON -->
								<pui-text-area
									:id="`rejectionreason-mships`"
									v-model="value.mship.rejectionreason"
									:label="$t('mships.rejectionreason')"
									:disabled="isCreatingElement"
									toplabel
								></pui-text-area>
							</v-flex>
						</v-layout>
					</pui-field-set>
				</v-flex>
				<v-flex xs12 md12 lg12 x12>
					<pui-field-set :title="getTitleAdditionalData">
						<v-layout wrap>
							<v-flex xs12 md4 lg4 x4>
								<pui-text-field
									:id="`mships-owneroperator`"
									v-model="value.mship.owneroperator"
									:label="$t('mships.owneroperator')"
									toplabel
									:disabled="isCreatingElement"
								></pui-text-field>
							</v-flex>

							<!-- registryportcountry -->
							<v-flex xs12 md4 lg4 x4>
								<pui-select
									id="`mships-registryportcountry`"
									attach="mships-registryportcountry"
									:label="$t('mships.registryportcountry')"
									toplabel
									:disabled="isCreatingElement"
									reactive
									clearable
									v-model="model"
									modelName="mports"
									:modelFormMapping="{ countrycode: 'certregistrycountry', portcode: 'certregistryport' }"
									:itemsToSelect="[{ countrycode: model.certregistrycountry, portcode: model.certregistryport }]"
									:itemValue="['countrycode', 'portcode']"
									:itemText="(item) => `${item.locode} - ${item.portdescription}`"
									:order="{ locode: 'asc' }"
								></pui-select>
							</v-flex>
							<v-flex xs12 md12 lg4 x4>
								<!-- CONSTRUCTIONDATE -->
								<pui-date-field
									:id="`constructiondate-mships`"
									v-model="value.mship.constructiondate"
									:label="$t('mships.constructiondate')"
									:disabled="isCreatingElement"
									toplabel
									:max="getMaxDate()"
								></pui-date-field>
							</v-flex>
							<!-- certregistrydate
									<v-flex xs12 md4 lg4 x4>
										<pui-date-field
											:id="`mships-certregistrydate`"
											:label="$t('mships.certregistrydate')"
											toplabel
											:disabled="formDisabled || this.model.shipstatusid == 3"
											v-model="model.certregistrydate"
										></pui-date-field>
									</v-flex>
									-->
							<v-flex xs12 md4 lg4 x4> </v-flex>
							<!-- certregistrynumber
									<v-flex xs12 md4 lg4 x4>
										<pui-text-field
											:id="`mships-certregistrynumber`"
											v-model="model.certregistrynumber"
											:label="$t('mships.certregistrynumber')"
											toplabel
											:disabled="formDisabled || this.model.shipstatusid == 3"
										></pui-text-field>
									</v-flex>
									-->
						</v-layout>
						<v-layout wrap>
							<v-flex xs12 md4 lg4 x4>
								<!-- CLASSSOCIETY -->
								<pui-text-field
									:id="`classsociety-mships`"
									v-model="value.mship.classsociety"
									:label="$t('mships.classsociety')"
									:disabled="isCreatingElement"
									toplabel
									maxlength="100"
								></pui-text-field>
							</v-flex>
							<v-flex xs12 md8 lg8 x8>
								<!-- CLASSNOTATION -->
								<pui-text-field
									:id="`classnotation-mships`"
									v-model="value.mship.classnotation"
									:label="$t('mships.classnotation')"
									:disabled="isCreatingElement"
									toplabel
								></pui-text-field>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<v-flex xs12 md12 lg12 x12>
								<!-- OBSERVATIONS -->
								<pui-text-area
									:id="`observations-mships`"
									v-model="value.mship.observations"
									:label="$t('mships.observations')"
									:disabled="isCreatingElement"
									toplabel
								></pui-text-area>
							</v-flex>
						</v-layout>
					</pui-field-set>
				</v-flex>
			</v-layout>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	name: 'shiptecnico-form',
	mixins: [PuiFormMethodsMixin],
	components: {},
	props: {
		value: {
			type: Object,
			required: false
		}
	},
	data() {
		return {
			modelName: 'mships',
			inivalidity: null,
			positions: [
				{
					text: this.$t('mships.Bow'),
					value: 'Bow'
				},
				{
					text: this.$t('mships.Stern'),
					value: 'Stern'
				}
			]
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server

			console.log(this.value.mship);
			if (this.value.mship != null) {
				this.model.certregistryport = this.value.mship.certregistryport;
				this.model.certregistrycountry = this.value.mship.certregistrycountry;
			}
			const parentModel = this.$puiUtils.getPathModelMethodPk(this.$router, this.parentModelName);

			if (parentModel) {
				this.value.mship.shipid = parentModel.pk.shipid;
			}
		},
		getMaxDate() {
			const currentDate = new Date();
			const year = currentDate.getFullYear();
			const month = currentDate.getMonth() + 1; // Meses de 0 a 11
			const day = currentDate.getDate();

			// Formateamos la fecha en el formato YYYY-MM-DD
			return `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;
		}
	},
	computed: {
		shiptypecodeItemsToSelect() {
			return [{ shiptypecode: this.value.mship.shiptypecode }];
		},
		flagItemsToSelect() {
			return [{ countrycode: this.value.mship.flag }];
		},
		shipstatusidItemsToSelect() {
			return [{ shipstatusid: this.value.mship.shipstatusid }];
		},
		thrusterpositionItemsToSelect() {
			return [{ value: this.value.mship.thrusterposition }];
		},
		measurementUnitCodeItemsToSelect() {
			return [{ measurementunitcode: this.value.mship.measurementunitcode }];
		},
		hulltypeidItemsToSelect() {
			return [{ hulltypeid: this.value.mship.hulltypeid }];
		},
		mooringlinetypeidItemsToSelect() {
			return [{ mooringlinetypeid: this.value.mship.mooringlinetypeid }];
		},
		getTitleTechnicalData() {
			return this.$t('mships.technicalData');
		},
		getTitleAdditionalData() {
			return this.$t('mships.additionalData');
		},
		getTitleGeneralData() {
			return this.$t('mships.generalData');
		},
		filterMeasurementUnitCode() {
			return {
				groups: [
					{
						groupOp: 'or',
						rules: [
							{ field: 'measurementunitcode', op: 'eq', data: 'LTR' },
							{ field: 'measurementunitcode', op: 'eq', data: 'MTQ' }
						]
					}
				]
			};
		}
	},
	created() {}
};
</script>
